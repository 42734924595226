import React from "react";
import ResponsiveAppBar from "../../components/NavigationBar/AppBar";
import {
  Box,
  Card,
  Grid,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import {
  Facebook,
  Twitter,
  LinkedIn,
  Instagram,
  GitHub,
} from "@mui/icons-material";
import ShareIcon from "@mui/icons-material/Share";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import Footer from "../../components/Footer/Footer";
import ContactForm from "../../components/ContactUs/ContactForm";

const ContactUs = () => {
  return (
    <>
      <ResponsiveAppBar />
      <Box sx={{ py: 6, px: 3, bgcolor: "#fafafa" }}>
        {/* Heading Section */}
        <Typography
          variant="h3"
          align="center"
          color="#603890"
          sx={{ fontWeight: 600, mb: 4 }}
        >
          Contact Us
        </Typography>

        {/* Two-column layout with Cards */}
        <Grid container spacing={6} justifyContent="center" mb={6}>
          {/* Social Media Card */}
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                py: 5,
                px: 3,
              }}
            >
              <ShareIcon sx={{ fontSize: 70, color: "#603890" }} />
              <Typography variant="h5" sx={{ mt: 3, fontWeight: 500 }}>
                Social Media
              </Typography>
              <Divider
                sx={{
                  my: 2,
                  borderColor: "#603890",
                  width: "50%",
                  mx: "auto",
                  borderWidth: 2,
                }}
              />
              <Typography variant="body1" sx={{ mb: 4 }}>
                Follow us on your favorite platforms
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
                <IconButton
                  color="primary"
                  href="https://facebook.com"
                  target="_blank"
                >
                  <Facebook />
                </IconButton>
                <IconButton
                  color="primary"
                  href="https://twitter.com"
                  target="_blank"
                >
                  <Twitter />
                </IconButton>
                <IconButton
                  color="primary"
                  href="https://linkedin.com"
                  target="_blank"
                >
                  <LinkedIn />
                </IconButton>
                <IconButton
                  color="primary"
                  href="https://instagram.com"
                  target="_blank"
                >
                  <Instagram />
                </IconButton>
                <IconButton
                  color="primary"
                  href="https://github.com"
                  target="_blank"
                >
                  <GitHub />
                </IconButton>
              </Box>
            </Card>
          </Grid>

          {/* Contact Details Card */}
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                py: 5,
                px: 3,
              }}
            >
              <MarkEmailUnreadIcon sx={{ fontSize: 70, color: "#603890" }} />
              <Typography variant="h5" sx={{ mt: 3, fontWeight: 500 }}>
                Contact
              </Typography>
              <Divider
                sx={{
                  my: 2,
                  borderColor: "#603890",
                  width: "50%",
                  mx: "auto",
                  borderWidth: 2,
                }}
              />
              <Typography variant="body1" sx={{ mb: 2 }}>
                <strong>For Businesses: </strong>business@retailstalents.com
              </Typography>
             
            </Card>
          </Grid>
        </Grid>

        {/* Contact Form Section */}
        <Box sx={{ mt: 6 }}>
          <ContactForm />
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default ContactUs;
