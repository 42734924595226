import React from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Container,
  Paper,
} from "@mui/material";

const ContactForm = () => {
  return (
    <Container sx={{ mb: 6 }}>
      <Box sx={{ mt: 6 }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: 600,
            textAlign: "center",
            mb: 3,
            mt: 10,
            color: "#603890",
          }}
        >
          Connect With Our Experts!
        </Typography>
        <Grid container spacing={6} alignItems="center">
          {/* Contact Form */}
          <Grid item xs={12} md={6} data-aos="fade-up" textAlign="center">
            <Paper
              sx={{
                padding: 4,
                maxWidth: 500,
                margin: "0 auto",
                borderRadius: 4,
                boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Full Name*"
                    variant="outlined"
                    size="medium"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Work Email*"
                    variant="outlined"
                    size="medium"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Phone*"
                    variant="outlined"
                    size="medium"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Company Name*"
                    variant="outlined"
                    size="medium"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Describe your requirement*"
                    variant="outlined"
                    multiline
                    rows={4}
                    size="medium"
                  />
                </Grid>
              </Grid>
              <Box sx={{ mt: 4, textAlign: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{
                    width: "100%",
                    bgcolor: "#603890",
                    ":hover": { bgcolor: "#4a2a70" },
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Paper>
          </Grid>

          {/* Content Section */}
          <Grid item xs={12} md={6} data-aos="fade-up">
            <Box
              sx={{
                textAlign: { xs: "center", md: "left" },
                px: { xs: 3, md: 0 },
              }}
            >
              <Typography
                variant="h2"
                gutterBottom
                sx={{
                  color: "#603890",
                  fontWeight: 700,
                  fontSize: { xs: "1.8rem", md: "2.5rem" },
                  mb: 4,
                }}
              >
                Your Premier Retail Recruitment Partner
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  mb: 4,
                  fontSize: "1rem",
                  lineHeight: 1.8,
                  color: "#555",
                }}
              >
               Retail Talents is your gateway to reliable and efficient hiring in the retail industry.
              </Typography>
              <Grid container spacing={3} justifyContent="center">
                {/* Business Stats */}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ContactForm;
